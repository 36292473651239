<template>
  <div class="single-day-page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'SingleDay',
};
</script>
<style scoped lang="scss">
  .single-day-page {
    width: 100%;
    max-width: 750px;
    margin: auto;
    height: 100%;
    position: relative;
    background: #fff;
  };
</style>
